/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
.bloc-contenu h2 {
  font-size: 32px;
}
.bloc-contenu h3 {
  font-size: 24px;
}
.bloc-contenu p {
  margin: 0 0 16px;
  font-size: 17px;
  line-height: 1.29;
}
.tuile-contenu .bloc-contenu p {
  font-size: 14px;
}

.grille-base {
  height: 100%;
}

.grille-hero {
  height: auto !important;
  min-height: 360px;
  padding: 60px 0;
  background-color: #fff4de;
  background-size: auto 100%;
}
@media screen and (min-width: 48em) {
  .grille-hero {
    min-height: 400px;
  }
}
@media screen and (min-width: 71.25em) {
  .grille-hero {
    min-height: 520px;
  }
}
.grille-hero .zone-hero-lien-retour {
  margin-bottom: 48px;
}
.grille-hero .zone-hero-texte {
  max-width: 352px;
  padding: 0;
}
@media screen and (min-width: 71.25em) {
  .grille-hero .zone-hero-texte {
    max-width: 475px;
  }
}
.grille-hero .zone-hero-texte .hero-separateur {
  display: none !important;
}
.grille-hero .zone-hero-texte .hero-titre {
  margin-bottom: 16px;
  font-size: 35px;
}
.grille-hero .zone-hero-texte .hero-sous-titre {
  max-width: none;
}

.grille-section-cta-sticky .col-1-1 {
  min-height: 0;
}

[class*=grille-cas] > .col-1-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
[class*=grille-cas] .bloc-contenu-texte {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
[class*=grille-cas] .bloc-contenu-solution {
  margin-top: auto;
  background-color: rgba(255, 255, 255, 0.6);
}
[class*=grille-cas] h3 {
  font-size: 21px;
}
[class*=grille-cas] hr {
  margin: 24px 0;
  border-top: 1px solid #d3d8de;
}
[class*=grille-cas] .list-bullet li {
  padding-left: 12px;
}

.grille-section-apprendre {
  background-color: #fff4de;
}

.grille-section-calcul .grille-1-colonne {
  padding: 300px 16px 60px;
  background-image: url("/-/media/Files/IA/accifamille/content/Accifamille_FaitesLeCalcul.jpg");
  background-position: top center;
  background-size: auto 240px;
  background-repeat: no-repeat;
}
@media screen and (min-width: 48em) {
  .grille-section-calcul .grille-1-colonne {
    padding: 60px 32px;
    background-size: auto 100%;
    background-position: calc(50% + 140px) 50%;
  }
}
@media screen and (min-width: 71.25em) {
  .grille-section-calcul .grille-1-colonne {
    padding: 48px 32px;
    background-position: calc(50% + 150px) 50%;
  }
}
@media screen and (min-width: 48em) {
  .grille-section-calcul .grille-1-colonne .bloc-contenu-texte {
    max-width: 312px;
  }
}
@media screen and (min-width: 71.25em) {
  .grille-section-calcul .grille-1-colonne .bloc-contenu-texte {
    max-width: 432px;
  }
}
.grille-section-calcul .grille-1-colonne .bloc-bta-wrapper {
  max-width: 300px;
  margin: 0;
  padding: 0;
}

.tableau-simple-colspan table tr td {
  text-align: right !important;
}
.tableau-simple-colspan table tr td:first-child {
  text-align: left !important;
}

@media print {
  @page {
    margin: 2cm;
  }
  * {
    float: none !important;
    position: static !important;
    min-height: 0 !important;
    max-height: none !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    background-color: transparent !important;
  }
  body {
    width: 100% !important;
    background: #fff !important;
    color: #000 !important;
    font-size: 12pt !important;
    line-height: 1.3 !important;
    text-align: left !important;
  }
  h1 {
    font-size: 24pt !important;
    margin: 0 0 24px !important;
  }
  h2 {
    display: block !important;
    font-size: 18pt !important;
    margin: 24px 0 !important;
  }
  h3,
  h4 {
    display: block !important;
    font-size: 16pt !important;
    margin: 12px 0 16px !important;
  }
  p {
    font-size: 12pt !important;
    margin-bottom: 12px !important;
  }
  ul {
    list-style-type: disc;
    margin-left: 40px !important;
  }
  li {
    padding: 0 !important;
    display: list-item;
    margin-bottom: 12px !important;
    text-align: left !important;
  }
  li:before {
    content: none !important;
  }
  br,
  img,
  #bandeau-alerte,
  #header,
  #grille-section-cta-sticky,
  #grille-section-cta,
  #grille-section-calcul,
  #grille-section-apprendre,
  .nav-secondaire,
  .zone-hero-lien-retour,
  .image-container,
  .icone-bloc-contenu,
  [class*=grille-cas] hr {
    display: none !important;
  }
  .bloc-contenu {
    text-align: left !important;
  }
  .margin-bottom-60 {
    margin: 0 !important;
  }
  .heroV2 .zone-hero-texte .hero-sous-titre {
    max-width: none;
  }
  .heroV2.hero-medium-ia .zone-hero-texte {
    padding: 0;
  }
  .tuile-contenu {
    position: relative !important;
    display: block !important;
  }
  .commun-tuilerie .tuile-action {
    border-bottom: 0 !important;
  }
  .tuiles-standard.commun-tuilerie .tuile .tuile-action:after {
    content: none !important;
  }
  table {
    margin-bottom: 12px !important;
  }
  .tableau-simple-colspan {
    overflow: visible;
  }
  td {
    min-width: 200px !important;
    padding: 8px 0 !important;
  }
  td[colspan]:not([colspan="1"]) {
    background: #f2f2f2 !important;
  }
  .background-color-grey-1,
  .background-color-soft-blue {
    background-color: transparent !important;
  }
}